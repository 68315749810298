import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import AccountTree from '@mui/icons-material/AccountTree';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton>
      <ListItemIcon>
        <AccountTree />
      </ListItemIcon>
      <ListItemText primary="CI / CD Pipeline" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <DeveloperBoardIcon />
      </ListItemIcon>
      <ListItemText primary="Architectural Diagram" />
    </ListItemButton>
    <ListItemButton>
      <ListItemIcon>
        <PsychologyIcon />
      </ListItemIcon>
      <ListItemText primary="Philosophy" />
    </ListItemButton>
  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>
    <ListSubheader component="div" inset>
      Portfolio
    </ListSubheader>
    <ListItemButton>
      <ListItemIcon>
        <CropOriginalIcon />
      </ListItemIcon>
      <ListItemText primary="TBD" />
    </ListItemButton>
  </React.Fragment>
);