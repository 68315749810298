import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

// import { makeStyles } from "@mui/styles";

// const useStyles = makeStyles((theme) => ({
//   mainContainer: {
//     background: "#233",
//     height: "100%",
//   },
//   cardContainer: {
//     maxWidth: 690,
//     margin: "3rem auto",
//   },
// }));

const projects = [
  {
    name: "TBD",
    description: `TBD`,
  },
];

// Can use Div and replace Box and Grid
const Portfolio = () => {
  // const classes = useStyles();
  return (
    <Box component="div">
      <Grid container justify="center">
        {/* Projects */}
        {projects.map((project, i) => (
          <Grid item xs={12} sm={8} md={9} key={i}>
            <Card>
              <CardActionArea>
                {/* <CardMedia
                  component="img"
                  alt="Project 1"
                  height="140"
                  image={project.image}
                /> */}
                <CardContent>
                  <Typography variant="h5" gutterBottom>
                    {project.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {project.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  CI/CD Pipeline
                </Button>
                <Button size="small" color="primary">
                  Architectural Diagram
                </Button>
                <Button size="small" color="primary">
                  Architectural Diagram
                </Button>
                <Button size="small" color="primary">
                  Go To Site
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Portfolio;
